import React from "react"

import Layout, {withCenter} from "../components/layout"
import SEO from "../components/seo"
import Services from "../components/index/services"
import LatestProjects from "../components/index/latestProjects";
import About from "../components/index/about";
import { outerSpacing } from "../theme/spacing";
import { dark, light } from "../theme/colors";
import HeroSection from "../components/index/heroSection";
import ContactUs from "../components/contact";
import arches from "../images/arches.png"

const CenteredServices = withCenter(Services)
const CenteredProjects = withCenter(LatestProjects)
const CenteredAbout = withCenter(About)
const CenteredContact = withCenter(ContactUs)

const IndexPage = () => (
  <Layout>
    <SEO title="Welcome to Simply Arch - Jasa Desain Arsitektur dan Interior Jakarta" 
        keywords={[`desain`, 'interior', `kontraktor`, `arsitektur`, `jasa`]} 
        description="Konsultan dan Kontraktor arsitekur interior Jakarta. Jasa interior desain rumah, apartemen, komersial, restoran, cafe, hotel, kantor sejak 2009"/>    
    <HeroSection />    
    <CenteredServices {...outerSpacing} {...light} id="services"/>
    <CenteredProjects {...outerSpacing} {...dark}/>
    <CenteredAbout {...outerSpacing} {...light} id="about"/>
    <CenteredContact {...outerSpacing} bg="neutral.8" 
      backgroundImage={`url(${arches})`} id="contact"/>
  </Layout>
)

export default IndexPage