import * as React from "react"
import {BoxProps} from "rebass"
import {Box} from "../responsiveComponents"
import { graphql, useStaticQuery } from "gatsby"
import { SectionTitle } from "../title"
import { InternalLink } from "../link";
import {fluidImage, allProjectImages} from "../fragments"
import { threeColGrid, twoColGrid } from "../../theme/spacing";
import { ProjectList } from "../projects";

const Projects = (props: BoxProps) => {
     const data = useStaticQuery(graphql`        
        query {
            projects: allProjectsYaml(
                sort: {fields: [year, name], order: [DESC, ASC]},
                limit: 6
                ) {
                nodes {
                    name
                    location
                    imageFolder
                    slug
                }
            }

             
            ...allProjectImages
        }
        `);     
    return (
        <Box {...props}>
            <SectionTitle>Latest Projects</SectionTitle>
            <ProjectList
                    projects={data.projects.nodes} 
                    images={data.allProjectImages.nodes} 
                    titleColor="neutral.8" 
                    subtitleColor="neutral.7"
                    childBoxProps={{
                        ...twoColGrid,
                        py: "vert.1",
                        my: "vert.3",
                    }}                   
                />           
            <InternalLink color="primary.5" my={"vert.2"} hovercolor="primary.6" to="/projects">See All Projects ></InternalLink>
        </Box>
    )
  }

export default Projects;