import * as React from "react"
import {BoxProps} from "rebass"
import {Box, Flex, Text, Image} from "../responsiveComponents"
import { SectionTitle, SectionTitleLeft } from "../title";
import {MobileQuery, DesktopQuery} from '../../theme/themeProvider'
import {StaticQuery, graphql, useStaticQuery} from 'gatsby'
import Img from "gatsby-image"
import { threeColGrid } from "../../theme/spacing";
import styled from "styled-components";

const AboutImg = styled(Img)`
    border-radius: 50%;
`

export default (props: BoxProps) => {
    const {placeholderImage, profileImage} = useStaticQuery(
        graphql`
            query {
                placeholderImage: file(relativePath: { eq: "about-img.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
                }
                profileImage: file(relativePath: { eq: "about-profile.jpg" }) {
                childImageSharp {
                    fixed(width: 135 height: 135) {
                    ...GatsbyImageSharpFixed_withWebp
                    }
                }
                }
            }
        `
    )
        return (        
            <Box {...props}>
                <MobileQuery>
                    <SectionTitle>About Us</SectionTitle>
                </MobileQuery>
                
                <Flex flexDirection="row-reverse" flexWrap="wrap" justifyContent="space-between">    
                    <Box {...threeColGrid} py={"vert.1"} mt={["vert.4", "vert.0"]}>
                        <Img fluid={placeholderImage.childImageSharp.fluid} alt="Photo of a house interior" />
                    </Box>                                    
                    <Box width={[1, 2/3]} pr={[0, 7]}>       
                        <DesktopQuery>
                            <SectionTitleLeft>About Us</SectionTitleLeft>
                        </DesktopQuery>
                        
                        <Text mt={"vert.2"}>
                            Established since 2009, Simply Arch is an architecture 
                            and interior design firm based in Jakarta. 
                            We provide design and construction services for residential 
                            and commercial projects. 
                            We also provide custom furnishing services to complement the design style.</Text>                    
                        <Flex flexDirection="row" alignItems="center" mt={"vert.4"}>
                            <Box pr={4}>
                                <AboutImg 
                                fixed={profileImage.childImageSharp.fixed} 
                                alt="Photo of the founder" />                                
                            </Box>
                            <Box ml={2}>
                                <Text fontSize={3}>Adrian Chen</Text>
                                <Text color="neutral.5">Founder / Chief Architect</Text>
                            </Box>                        
                        </Flex>                    
                    </Box>
                   
                </Flex>
            </Box>
        )
}