import * as React from "react"
import { graphql, StaticQuery } from 'gatsby'
import { Hero } from "../hero";

const HeroSection = () => {
  return <StaticQuery query={graphql`
      query {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `} render={data => {
       const imageData = data.hero.childImageSharp.fluid
       return (
           <Hero 
            imageFluid={imageData}
            heroTitle="Build Your Dream Home"
            heroSubtitle="We deliver creative ideas to your living space"
            heroButtonTitle="Contact Us"
            darkness={0.45} 
            />
       )
    }} />
}

export default HeroSection

