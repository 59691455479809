import * as React from "react"
import {BoxProps} from "rebass"
import {Box, Flex, Text, Image} from "../responsiveComponents"
import {TiEdit, TiSpanner, TiHome} from "react-icons/ti";
import { IconType } from "react-icons/lib/cjs";
import {SectionTitle} from "../title"
import { withTheme } from "styled-components";
import { threeColGrid } from "../../theme/spacing";
import ArchitectureIcon from "../../images/petronas-twin-tower.png"
import InteriorIcon from "../../images/interior-design.png"
import FurnishIcon from "../../images/shelf.png"

interface ServiceProps extends BoxProps {
    serviceName: string,
    serviceDescription: string,
    icon: JSX.Element
}

interface IconProps {
    icon: string,
    theme: any,
    alt: string
}

const Icon = (props: IconProps) => (
    <Image src={props.icon} 
        height="2.5em" 
        width="2.5em"
        alt={props.alt}
        />
)

const ServiceIcon = withTheme(Icon)

const Service = (props: ServiceProps) => {    
        return (        
            <Box {...threeColGrid}
                py={["vert.1"]}            
                my={"vert.3"}            
                alignSelf="stretch"
                bg="neutral.8">
                <Flex                                         
                    flexDirection="column"       
                    px={[2,3]}
                    py={"vert.4"}>
                    <Box mx={4} my={"vert.1"}>
                        {props.icon}
                    </Box>
                    <Box mx={4}>
                        <h4>{props.serviceName}</h4>   
                    </Box>
                    <Box mx={4}>
                        <Text>{props.serviceDescription}</Text>
                    </Box>                                  
                </Flex>
            </Box>
        )
}

export default class Services extends React.Component {
  render() {
    return (
        <Box {...this.props}>
            <SectionTitle>Our Services</SectionTitle>
            <Flex flexDirection="row" flexWrap="wrap" 
                justifyContent="space-between" alignItems="stretch">
                <Service                     
                    serviceName="Architecture"
                    serviceDescription="We turn ideas and aspirations into reality, guiding you through the design, planning and construction process." 
                    icon={<ServiceIcon icon={ArchitectureIcon} alt="Building icon"/>}
                    />
                
                <Service                     
                    serviceName="Interior Design"
                    serviceDescription="We create innovative layouts and designs, placing utmost attention to the finest details when conceptualizing your requirements to suit your lifestyle." 
                    icon={<ServiceIcon icon={InteriorIcon} alt="Room layout icon"/>}/>

                <Service                     
                    serviceName="Furnish and Decor"
                    serviceDescription="We provide high quality workmanship and materials for furnitures and decorations." 
                    icon={<ServiceIcon icon={FurnishIcon} alt="A plant with a clock on the wall icon"/>}/>
            </Flex>
        </Box>
    )
  }
}